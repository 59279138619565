import React from "react";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import "./App.less";
import Loading from "./components/loading/Loading";
import { Layout, Menu, BackTop, Button } from "antd";
import routes from "./routes";
import WeiotsLogo from "./assets/iki-logo.png";
import { createFromIconfontCN, MenuOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import ReactGA from "react-ga";
import Img from "react-cool-img";

const Footer = React.lazy(() => import("./components/Footer/Footer"));

ReactGA.initialize("G-QE2FZVJHX3");

const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2372312_1swxkgabrem.js",
});

const { Header, Content } = Layout;

const App = (props) => {
  const is654 = useMediaQuery({
    query: "(max-width: 672px)",
  });
  const [path, setPath] = React.useState("/home");
  const [collapse, setcollapse] = React.useState(true);

  const getPath = () => {
    return window.location.pathname;
  };
  const pathdata = getPath();

  React.useEffect(() => {
    // console.log(pathdata, "PATHDATA");
    let path = pathdata;
    if (pathdata.includes("/product")) {
      path = "/product";
    }
    setPath(path === "/" ? "/home" : path);
    ReactGA.pageview(path === "/" ? "/home" : path);
  }, [pathdata]);

  // console.log(window.location.href, "HREF");

  return (
    <BrowserRouter>
      <Layout className="layout" style={{ background: "unset" }}>
        <Header style={{ background: "unset" }}>
          <Menu
            style={{
              background: "#fff",
            }}
            mode="horizontal"
            onSelect={(e) => setPath(e.selectedKeys[0])}
            theme="light"
            selectedKeys={[path]}
            expandIcon={<MenuOutlined />}
          >
            <Menu.Item style={{ float: "left" }} key="/home" className="logo">
              <Link to="/">
                <Img
                  src={WeiotsLogo}
                  height={44}
                  width={108}
                  className="weiots-logo"
                  alt="Weiots logo"
                />
              </Link>
            </Menu.Item>
            {!is654 ? (
              [
                <Menu.Item key="/contact-us" style={{ float: "right" }}>
                  <Link to="/contact-us">Contact Us</Link>
                </Menu.Item>,
                <Menu.Item key="/about-us" style={{ float: "right" }}>
                  <Link to="/about-us">About Us</Link>
                </Menu.Item>,
                <Menu.Item key="/blog" style={{ float: "right" }}>
                  <a href={`${window.location.origin}/blog/`}>Blog</a>
                </Menu.Item>,
                <Menu.Item key="/product" style={{ float: "right" }}>
                  <Link to="/product/iot-sensors">Product</Link>
                </Menu.Item>,
                <Menu.Item key="/solution" style={{ float: "right" }}>
                  <Link to="/solution">Solution</Link>
                </Menu.Item>,
                <Menu.Item key="/home" style={{ float: "right" }}>
                  <Link to="/">Home</Link>
                </Menu.Item>,
              ]
            ) : (
              <Menu.Item style={{ float: "right" }}>
                <Button size="large" onClick={() => setcollapse(!collapse)}>
                  <IconFont
                    type="icon-menu"
                    style={{ fontSize: "16px", margin: 0 }}
                  />
                </Button>
              </Menu.Item>
            )}
          </Menu>
        </Header>
        <Menu
          theme="light"
          mode="inline"
          className="sider-custom"
          onSelect={(e) => setPath(e.selectedKeys[0])}
          selectedKeys={[path]}
          inlineCollapsed={collapse}
        >
          <Menu.Item key="/home" className="logo" style={{ margin: "12px 0" }}>
            <Link to="/">
              <Img src={WeiotsLogo} className="weiots-logo" alt="weiotslogo" />
            </Link>
          </Menu.Item>
          <Menu.Divider></Menu.Divider>
          <Menu.Item key="/home">
            <Link to="/">Home</Link>
          </Menu.Item>
          <Menu.Item key="/solution">
            <Link to="/solution">Solution</Link>
          </Menu.Item>
          <Menu.Item key="/product">
            <Link to="/product/iot-sensors">Product</Link>
          </Menu.Item>
          <Menu.Item key="/blog">
            <a href={`${window.location.origin}/blog/`}>Blog</a>
          </Menu.Item>
          <Menu.Item key="/about-us">
            <Link to="/about-us">About Us</Link>
          </Menu.Item>
          <Menu.Item key="/contact-us">
            <Link to="/contact-us">Contact Us</Link>
          </Menu.Item>
        </Menu>
      </Layout>
      <Content onClick={() => setcollapse(true)}>
        <React.Suspense fallback={Loading()}>
          <Switch>
            {routes.map((route, idx) => {
              return route.component ? (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={(props) => (
                    <route.component {...props} name={route.name} />
                  )}
                />
              ) : null;
            })}
          </Switch>
        </React.Suspense>
      </Content>

      <React.Suspense fallback={Loading()}>
        <Footer
          IconFont={IconFont}
          Link={Link}
          setPath={setPath}
          setcollapse={setcollapse}
          key="footer"
        />
      </React.Suspense>
      <BackTop />
    </BrowserRouter>
  );
};

export default App;
